import { gql } from "@apollo/client";

export const SECTION_2_FRAGMENT = gql`
  fragment Section2 on ComponentCardSection2 {
    Description
    Title
    slug
    desc_rich
    TitleIconSection(pagination: $titleIconSectionPagination) {
      ...TitleIcon
    }
  }
`;
