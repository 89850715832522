import { HOME_PAGE_TECH_CATEGORY } from "./HomePage/Home";
import { HEADER_QUERY } from "./Header/header";
import { FOOTER_QUERY } from "./Footer/footer";
import { TECH_PAGE_QUERY } from "./Technology/technologies";
import { TECH_BY_SLUG_QUERY } from "./Technology/technology";
import { PORTFOLIO_QUERY } from "./Portfolio/portfolio";
import { BLOGS_QUERY } from "./Blog/blogs";
import { BLOG_BY_SLUG } from "./Blog/blog";
import { PRICING_PLAN_PAGE_QUERY } from "./PricingAndPlan/pricingAndPlan";
import { TESTIMONIALS_QUERY } from "./Testimonial/testimonials";
import { ABOUT_QUERY } from "./About/about";
import { CAREER_QUERY } from "./Career/careers";
import { WHY_CHOOSE_US_QUERY } from "./WhyChooseUs/whyChooseUs";
import { LOCATION_QUERY } from "./Location/location";
import { MISSION_VISION_VALUES_QUERY } from "./MissionVisionValue/missionVisionValues";
import { CONTACT_PAGE_QUERY } from "./Contact/contact";
import { INDUSTRY_QUERY, INDUSTRY_PAGES_QUERY } from "./Industry/Industry";

export {
  HOME_PAGE_TECH_CATEGORY,
  HEADER_QUERY,
  FOOTER_QUERY,
  TECH_PAGE_QUERY,
  TECH_BY_SLUG_QUERY,
  PORTFOLIO_QUERY,
  BLOGS_QUERY,
  BLOG_BY_SLUG,
  PRICING_PLAN_PAGE_QUERY,
  TESTIMONIALS_QUERY,
  ABOUT_QUERY,
  CAREER_QUERY,
  WHY_CHOOSE_US_QUERY,
  LOCATION_QUERY,
  MISSION_VISION_VALUES_QUERY,
  CONTACT_PAGE_QUERY,
  INDUSTRY_QUERY,
  INDUSTRY_PAGES_QUERY,
};
