import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SEO_FRAGMENT,
  TITLE_ICON_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const WHY_CHOOSE_US_QUERY = gql`
  query WHY_CHOOSE_US_QUERY {
    whyChooseUs {
      data {
        attributes {
          MainSec {
            ...Banner
          }
          Contact_Us {
            ...Banner
          }
          Sections {
            Description
            Title
            slug
            TitleIconSection(pagination: { limit: 100 }) {
              ...TitleIcon
            }
            image {
              ...Image
            }
          }
          SEO {
            ...SEO
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
  ${TITLE_ICON_FRAGMENT}
`;
