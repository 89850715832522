import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SEO_FRAGMENT,
  TITLE_ICON_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const MISSION_VISION_VALUES_QUERY = gql`
  query MISSION_VISION_VALUES_QUERY {
    missionAndValue {
      data {
        attributes {
          MainSec {
            ...Banner
          }
          Contact_Us {
            ...Banner
          }
          SEO {
            ...SEO
          }
          Sections {
            Description
            Title
            btn_text
            btn_url
            Icons {
              ...TitleIcon
            }
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
  ${TITLE_ICON_FRAGMENT}
`;
