import { gql } from "@apollo/client";

export const TITLE_ICON_FRAGMENT = gql`
  fragment TitleIcon on ComponentGeneralTitleIconGroup {
    category
    Description
    Icon {
      ...Image
    }
    Title
  }
`;
