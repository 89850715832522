import { gql } from "@apollo/client";

export const ICON_FRAGMENT = gql`
  fragment Icon on ComponentGeneralIcon {
    Name
    Icon {
      ...Image
    }
    count
  }
`;
