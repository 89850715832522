import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const client = new ApolloClient({
    uri: process.env.NEXT_PUBLIC_SERVER_GRAPHQL_URL || 'http://localhost:1337/graphql',
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: process.env.NEXT_PUBLIC_SERVER_GRAPHQL_URL || 'http://localhost:1337/graphql'
    })
});

export default client;