import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SEO_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const PRICING_PLAN_PAGE_QUERY = gql`
  query PricingAndPlanQuery {
    pricingPlan {
      data {
        attributes {
          MainSec {
            Subtitle
            Title
            Description
            banner_image {
              ...Image
            }
          }
          plans {
            data {
              attributes {
                Description
                Price
                Title
                is_popular
                Services
                Button {
                  reference
                  text
                }
              }
            }
          }
          Contact_Us {
            ...Banner
          }
          SEO {
            ...SEO
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
`;
