import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  IconButton,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import NextLink from "next/link";
import Image from "next/image";
import { FOOTER_QUERY } from "@/apollo/queries";
import Loader from "../Loader";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"medium"} fontSize={"xl"} mb={2} color={"blue.10"}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const { data, loading, error } = useQuery(FOOTER_QUERY);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const { Description, LinkGroup, Logo, social_links, locations } =
    data.footer.data.attributes;

  return (
    <Box
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.700", "gray.200")}
      px={{ base: 5, md: 8, xl: 5 }}
    >
      <Container
        as={Stack}
        maxW={"7xl"}
        px={0}
        py={{ base: 10, md: 10, lg: 16 }}
      >
        <SimpleGrid
          templateColumns={{ sm: "1fr ", md: "1fr 1fr", lg: "2fr 3fr 2fr" }}
          spacing={{ base: 2, md: 8 }}
        >
          <Stack spacing={6}>
            <Box mb={3}>
              <NextLink href="/">
                <Image
                  className="mb-0"
                  height={100}
                  width={220}
                  src={
                    process.env.NEXT_PUBLIC_SERVER_URL +
                    Logo.Icon.data.attributes.url
                  }
                  alt={Logo.Icon.data.attributes.name}
                  unoptimized
                />
              </NextLink>
            </Box>
            <Text
              fontSize={"lg"}
              fontWeight={"normal"}
              color={"gray.10"}
              mt={"0 !important"}
            >
              {Description}
            </Text>
          </Stack>
          <Flex justifyContent={"space-around"} pt={3}>
            {LinkGroup.map((grp, index) => {
              return (
                <Stack
                  key={index}
                  align={"flex-start"}
                  fontSize={"sm"}
                  fontWeight={"normal"}
                  color={"gray.10"}
                >
                  <ListHeader>{grp?.header}</ListHeader>
                  {grp?.link.map((url, index) => {
                    return (
                      <NextLink key={index} href={url.reference}>
                        <Link
                          position={"relative"}
                          _hover={{
                            color: "orange.10",
                          }}
                          fontSize={"lg"}
                        >
                          {url.text}
                        </Link>
                      </NextLink>
                    );
                  })}
                </Stack>
              );
            })}
          </Flex>

          <Stack align={"flex-start"} pt={3}>
            <ListHeader>Connect With Us</ListHeader>
            {locations.data?.map((loc, index) => {
              const { path, viewbox, Name, Fill } = loc.attributes.SVG;
              return (
                <Flex key={index}>
                  <IconButton
                    aria-label={Name}
                    mr={"2"}
                    variant="ghost"
                    size="lg"
                    isRound={true}
                    alignItems={"flex-start"}
                    _hover={{ bg: "trnsparent" }}
                    icon={
                      <svg
                        width="24"
                        height="24"
                        className="chakra-icon"
                        viewBox={viewbox || "0 0 24 24"}
                        fill={Fill}
                        dangerouslySetInnerHTML={{ __html: path }}
                      />
                    }
                  />
                  <Text fontSize={"lg"} fontWeight={"normal"} color={"gray.10"}>
                    {loc.attributes.Address}
                  </Text>
                </Flex>
              );
            })}
            <Flex>
              {social_links.data?.map((soc, index) => {
                const { path, viewbox, Name, Fill } = soc.attributes.Icon;
                return (
                  <Link
                    key={index}
                    target="_blank"
                    href={soc.attributes.url}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      aria-label={Name}
                      mr={"2"}
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: "gray.200" }}
                      icon={
                        <svg
                          width="24"
                          height="24"
                          className="chakra-icon"
                          viewBox={viewbox || "0 0 24 24"}
                          fill={"black"}
                          dangerouslySetInnerHTML={{ __html: path }}
                        />
                      }
                    />
                  </Link>
                );
              })}
            </Flex>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
