import { IMAGE_FRAGMENT, SEO_FRAGMENT, SVG_FRAGMENT } from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const CONTACT_PAGE_QUERY = gql`
  query CONTACT_PAGE_QUERY {
    contactPage {
      data {
        attributes {
          Description
          Title
          locations {
            data {
              attributes {
                Address
                Phone
                Icon {
                  ...Image
                }
                SVG {
                  ...SVG
                }
              }
            }
          }
          MainSec {
            Description
            Title
            banner_image {
              ...Image
            }
          }
          social_links {
            data {
              attributes {
                Name
                value
                Icon {
                  ...SVG
                }
                url
              }
            }
          }
          SEO {
            ...SEO
          }
        }
      }
    }
  }
  ${SEO_FRAGMENT}
  ${SVG_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;
