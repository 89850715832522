import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  // SECTION_2_FRAGMENT,
  SEO_FRAGMENT,
  TITLE_ICON_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const INDUSTRY_PAGES_QUERY = gql`
  query getIndustrySlugs($FilterArgs: String) {
    navbar {
      data {
        attributes {
          Header(filters: { Slug: { eq: $FilterArgs } }) {
            Slug
            SubMenu {
              url
            }
          }
        }
      }
    }
  }
`;

export const INDUSTRY_QUERY = gql`
  query IndustryQuery($FilterArg: ComponentCardSection2FiltersInput) {
    industry {
      data {
        attributes {
          Sections(filters: $FilterArg) {
            Description
            Title
            slug
            desc_rich
            MainSec {
              Title
              Description
              banner_image {
                ...Image
              }
            }
            TitleIconSection {
              id
              Title
              Description
              Icon {
                ...Image
              }
              category
            }
            ProjectSection {
              id
              Title
              Description
              Icon {
                ...Image
              }
            }
          }
          SEO {
            ...SEO
          }

          Contact_Us {
            ...Banner
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
`;
// ${TITLE_ICON_FRAGMENT}
