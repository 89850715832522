import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SEO_FRAGMENT,
  TECHNOLOGY_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

// export const TECH_PAGE_QUERY = gql`
//   query TechPageQuery {
//     techPage {
//       data {
//         attributes {
//           Contact_Us {
//             ...Banner
//           }
//           MainSec {
//             ...Banner
//           }
//           SEO {
//             ...SEO
//           }
//           name
//           tech_categories(sort: "id:ASC") {
//             data {
//               attributes {
//                 Name
//                 technologies {
//                   ...Technology
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   ${BANNER_FRAGMENT}
//   ${IMAGE_FRAGMENT}
//   ${SEO_FRAGMENT}
//   ${TECHNOLOGY_FRAGMENT}
// `;

export const TECH_PAGE_QUERY = gql`
  query TechPageQuery {
    techPage {
      data {
        attributes {
          Contact_Us {
            ...Banner
          }
          MainSec {
            ...Banner
          }
          SEO {
            ...SEO
          }
          name
          technologies {
            data {
              attributes {
                name
                slug
                Icon {
                  ...Image
                }
                Description
                tech_category {
                  data {
                    attributes {
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
`;
