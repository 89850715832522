import { BANNER_FRAGMENT, IMAGE_FRAGMENT, SEO_FRAGMENT } from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const LOCATION_QUERY = gql`
  query LOCATION_PAGE {
    locationPage {
      data {
        attributes {
          MainSec {
            ...Banner
          }
          Contact_Us {
            ...Banner
          }
          SEO {
            ...SEO
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
`;
