import { gql } from "@apollo/client";
import { IMAGE_FRAGMENT } from '../Image/index'

export const BANNER_FRAGMENT = gql`
  fragment Banner on ComponentCardContactUs {
    Description
    Subtitle
    Title
    btn_text
    btn_url
    banner_image {
      ...Image
    }
  }
`;
