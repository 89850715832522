import React from "react";
import { Spinner } from "@chakra-ui/react";

const Loader = ({ ...options }) => {
  return (
    <div style={{ padding: "2px" }}>
      <Spinner {...options} />
    </div>
  );
};

Loader.defaultProps = {
  thickness: "2px",
  speed: "0.65s",
  emptyColor: "gray.200",
  color: "blue.500",
  size: "md",
};

export default Loader;
