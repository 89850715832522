import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SECTION_2_FRAGMENT,
  SEO_FRAGMENT,
  TITLE_ICON_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const ABOUT_QUERY = gql`
  query ABOUT_PAGE($titleIconSectionPagination: PaginationArg) {
    aboutPage {
      data {
        attributes {
          Banner {
            ...Banner
          }
          Contact_Us {
            ...Banner
          }
          Images {
            data {
              attributes {
                name
                url
              }
            }
          }
          Sections {
           ...Section2
          }
          SEO {
            ...SEO
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
  ${SECTION_2_FRAGMENT}
  ${TITLE_ICON_FRAGMENT}
`;
