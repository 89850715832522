import { ICON_FRAGMENT, IMAGE_FRAGMENT, SVG_FRAGMENT } from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const FOOTER_QUERY = gql`
  query FooterQuery {
    footer {
      data {
        attributes {
          Description
          LinkGroup {
            header
            id
            link {
              text
              reference
              id
            }
          }
          Logo {
            ...Icon
          }
          social_links {
            data {
              attributes {
                Name
                url
                value
                Icon {
                  ...SVG
                }
              }
            }
          }
          locations {
            data {
              attributes {
                Address
                Phone
                Icon {
                  ...Image
                }
                SVG {
                  ...SVG
                }
              }
            }
          }
        }
      }
    }
  }
  ${SVG_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${ICON_FRAGMENT}
`;
