import { gql } from "@apollo/client";

export const SECTION_FRAGMENT = gql`
  fragment Section on ComponentCardSection {
    Name
    Head {
      ...HeadTitle
    }
    Icons {
      ...Icon
    }
  }
`;
