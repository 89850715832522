import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SECTION_2_FRAGMENT,
  SEO_FRAGMENT,
  TITLE_ICON_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const CAREER_QUERY = gql`
  query CAREER_QUERY($titleIconSectionPagination: PaginationArg) {
    career {
      data {
        attributes {
          MainSec {
            Description
            Title
            banner_image {
              ...Image
            }
          }
          Title
          Description
          current_openings(sort: "id:ASC") {
            data {
              attributes {
                Developer
                Experience
                Qualification
                Vacancies
                isOpening
              }
            }
          }
          Benefits {
            ...Section2
          }
          Contact_Us {
            ...Banner
          }
          SEO {
            ...SEO
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
  ${SECTION_2_FRAGMENT}
  ${TITLE_ICON_FRAGMENT}
`;
