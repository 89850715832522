import { gql } from "@apollo/client";

export const SVG_FRAGMENT = gql`
  fragment SVG on ComponentGeneralSvgIcon {
    Name
    viewbox
    path
    Fill
  }
`;
