import { BANNER_FRAGMENT } from "./Banner/index";
import { IMAGE_FRAGMENT } from "./Image/index";
import { SEO_FRAGMENT } from "./SEO/index";
import { ICON_FRAGMENT } from "./Icon/index";
import { HEAD_TITLE_FRAGMENT } from "./HeadTitle/index";
import { SECTION_FRAGMENT } from "./Section/index";
import { TECHNOLOGY_FRAGMENT } from "./Technology/index";
import { TECH_CATEGORY_FRAGMENT } from "./TechCategory/index";
import { SECTION_2_FRAGMENT } from "./Section/section2";
import { TITLE_ICON_FRAGMENT } from "./TitleIcon/index";
import { SVG_FRAGMENT } from "./SVG/index";

export {
    BANNER_FRAGMENT,
    IMAGE_FRAGMENT,
    SEO_FRAGMENT,
    ICON_FRAGMENT,
    SECTION_FRAGMENT,
    HEAD_TITLE_FRAGMENT,
    TECH_CATEGORY_FRAGMENT,
    TECHNOLOGY_FRAGMENT,
    SECTION_2_FRAGMENT,
    TITLE_ICON_FRAGMENT,
    SVG_FRAGMENT
};
