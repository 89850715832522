import { gql } from "@apollo/client";

export const TECH_CATEGORY_FRAGMENT = gql`
  fragment TechCategory on TechCategoryEntityResponseCollection {
    data {
      attributes {
        Name
        technologies {
          ...Technology
        }
      }
    }
  }
`;
