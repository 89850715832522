import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SECTION_2_FRAGMENT,
  SEO_FRAGMENT,
  TITLE_ICON_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

// old query
// export const PORTFOLIO_QUERY = gql`
//   query PortfolioQuery($titleIconSectionPagination: PaginationArg) {
//     portfolio {
//       data {
//         attributes {
//           Sections {
//             ...Section2
//           }
//           Contact_Us {
//             ...Banner
//           }
//           SEO {
//             ...SEO
//           }
//         }
//       }
//     }
//   }
//   ${BANNER_FRAGMENT}
//   ${IMAGE_FRAGMENT}
//   ${SEO_FRAGMENT}
//   ${SECTION_2_FRAGMENT}
//   ${TITLE_ICON_FRAGMENT}
// `;

export const PORTFOLIO_QUERY = gql`
  query PortfolioQuery {
    portfolio {
      data {
        attributes {
          Contact_Us {
            ...Banner
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;
