import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SECTION_2_FRAGMENT,
  SEO_FRAGMENT,
  TITLE_ICON_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const TECH_BY_SLUG_QUERY = gql`
  query SingleTechQuery(
    $filters: TechnologyFiltersInput
    $titleIconSectionPagination: PaginationArg
  ) {
    technologies(filters: $filters) {
      data {
        attributes {
          name
          slug
          SEO {
            ...SEO
          }
          MainSec {
            ...Banner
          }
          Middle_Sections {
            ...Section2
          }
          Contact_Us {
            ...Banner
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
  ${SECTION_2_FRAGMENT}
  ${TITLE_ICON_FRAGMENT}
`;
