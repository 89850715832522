import { gql } from "@apollo/client";

export const IMAGE_FRAGMENT = gql`
  fragment Image on UploadFileEntityResponse {
    data {
      attributes {
        name
        url
      }
    }
  }
`;
