import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SEO_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

// # old query before scroll pagination implementation
// export const BLOGS_QUERY = gql`
//   query BlogPageQuery {
//     blogPage {
//       data {
//         attributes {
//           blogs {
//             data {
//               attributes {
//                 Shot_Description
//                 Title
//                 slug
//                 Image {
//                   ...Image
//                 }
//               }
//             }
//           }
//           Contact_Us {
//             ...Banner
//           }
//           Title
//           Description
//           banner_image {
//             ...Image
//           }
//           SEO {
//             ...SEO
//           }
//         }
//       }
//     }
//   }
//   ${BANNER_FRAGMENT}
//   ${IMAGE_FRAGMENT}
//   ${SEO_FRAGMENT}
// `;

// export const BLOGS_QUERY = gql`
//   query fetchBlogsWithPagination($blogsQueryPagination: PaginationArg) {
//     blogs(pagination: $blogsQueryPagination) {
//       data {
//         attributes {
//           Shot_Description
//           Title
//           slug
//           Image {
//             ...Image
//           }
//         }
//       }
//       meta {
//         pagination {
//           pageCount
//           total
//           page
//           pageSize
//         }
//       }
//     }
//     blogPage {
//       data {
//         attributes {
//           Title
//           Description
//           banner_image {
//             ...Image
//           }
//           Contact_Us {
//             ...Banner
//           }
//           SEO {
//             ...SEO
//           }
//         }
//       }
//     }
//   }
//   ${BANNER_FRAGMENT}
//   ${IMAGE_FRAGMENT}
//   ${SEO_FRAGMENT}
// `;

export const BLOGS_QUERY = gql`
  query fetchBlogsWithPagination($blogsQueryPagination: PaginationArg) {
    blogs(pagination: $blogsQueryPagination) {
      data {
        attributes {
          Title
          Shot_Description
          slug
          Image {
            ...Image
          }
        }
      }
      meta {
        pagination {
          pageCount
          total
          page
          pageSize
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;
