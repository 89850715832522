import { gql } from "@apollo/client";

export const TECHNOLOGY_FRAGMENT = gql`
  fragment Technology on TechnologyRelationResponseCollection {
    data {
      attributes {
        Description
        Icon {
          ...Image
        }
        name
        slug
      }
    }
  }
`;
