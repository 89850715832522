import { extendTheme } from "@chakra-ui/react"

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
    colors: {
        blue: {
            10: "#0e2b5c",
            20: "#f7fafc",
            30: "#bfe0fd"
        },
        gray: {
            10: "#627792",
            20: "#6277923d",
        },
        lightGray: {
            10: "#ddd",
        },
        orange: {
            10: "#fc5546",
            20: "#fc554626"
        },
        white: {
            10: "#ffffff",
            20: "#F3F4F6",
            30: "#f2f5f7"
        },
        border: {
            10: "#6e6d8638",
        },
        lightBlue: {
            10: "#627792"
        },
        purple: {
            10: '#231834'
        },
        skyBlue: {
            10: '#23527c'
        }
    },
})

export default theme