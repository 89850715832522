import {
  BANNER_FRAGMENT,
  IMAGE_FRAGMENT,
  SEO_FRAGMENT,
} from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const TESTIMONIALS_QUERY = gql`
  query Data {
    testimonialPage {
      data {
        attributes {
          Contact_Us {
            ...Banner
          }
          MainSec {
            Description
            Subtitle
            Title
            banner_image {
              ...Image
            }
          }
          testimonials {
            data {
              attributes {
                Content
                Country
                Name
                Role
                avatar {
                  ...Image
                }
              }
            }
          }
          SEO {
            ...SEO
          }
        }
      }
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
`;
