import { ICON_FRAGMENT, IMAGE_FRAGMENT } from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const HEADER_QUERY = gql`
  query NavQuery {
    navbar {
      data {
        attributes {
          Header {
            Title
            Url
            Slug
            SubMenu {
              Title
              category {
                data {
                  attributes {
                    Name
                  }
                }
              }
              id
              slug
              url
            }
          }
          Logo {
            ...Icon
          }
          News {
            header
            link {
              id
              text
            }
          }
        }
      }
    }
  }
  ${ICON_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;
