import {
  BANNER_FRAGMENT,
  HEAD_TITLE_FRAGMENT,
  ICON_FRAGMENT,
  IMAGE_FRAGMENT,
  SECTION_FRAGMENT,
  SEO_FRAGMENT,
  TECHNOLOGY_FRAGMENT,
  TECH_CATEGORY_FRAGMENT,
} from "@/apollo/fragments";

const { gql } = require("@apollo/client");

export const HOME_PAGE_TECH_CATEGORY = gql`
  query HomePageQuery {
    homePage {
      data {
        attributes {
          Head {
            ...Banner
          }
          Contact_Us {
            ...Banner
          }
          SEO {
            ...SEO
          }
          Sections {
            ...Section
          }
        }
      }
    }
    techCategories(sort: "id:ASC") {
      ...TechCategory
    }
  }
  ${BANNER_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
  ${ICON_FRAGMENT}
  ${SECTION_FRAGMENT}
  ${HEAD_TITLE_FRAGMENT}
  ${TECHNOLOGY_FRAGMENT}
  ${TECH_CATEGORY_FRAGMENT}
`;
