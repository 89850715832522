import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Icon,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  PopoverTrigger,
  Popover,
  Text,
  PopoverContent,
  Collapse,
  Grid,
  Container,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Td,
  Th,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import { gql, useQuery } from "@apollo/client";
import NextLink from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import { HEADER_QUERY } from "@/apollo/queries";
import Loader from "../Loader";

const TechNavList = ({ data, isActivePopOver }) => {
  const namesObj = {};

  data?.forEach((item) => {
    const name = item?.category?.data?.attributes.Name;
    if (namesObj[name]) {
      namesObj[name].push(item.Title);
    } else {
      namesObj[name] = [item.Title];
    }
  });

  const keys = Object.keys(namesObj);
  const values = Object.values(namesObj);

  return (
    <>
      <Thead>
        <Tr>
          {keys.map((heading, i) => {
            return (
              <Th key={i}>
                <Text
                  fontSize={"sm"}
                  borderBottom={"1px"}
                  borderColor={"orange.10"}
                  borderStyle={"solid"}
                  pb={3}
                >
                  {heading}
                </Text>
              </Th>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {values.map((item, i) => {
          return (
            <Td
              key={i}
              borderRight={"1px"}
              borderColor={"gray.100"}
              borderStyle={"solid"}
              py={1}
              verticalAlign={"top"}
            >
              {item?.map((language) => {
                const href = data.filter((x) => x.Title === language)[0]?.url;
                return (
                  <ul>
                    <NextLink
                      className={isActivePopOver ? "isactive" : "notactive"}
                      href={href ?? "#"}
                    >
                      <Link
                        role={"group"}
                        display={"block"}
                        p={2}
                        rounded={"md"}
                        _hover={{
                          bg: useColorModeValue("messenger.10", "gray.900"),
                        }}
                      >
                        <Stack
                          direction={"row"}
                          align={"center"}
                          pos="relative"
                        >
                          <Box>
                            <Text
                              transition={"all .3s ease"}
                              _groupHover={{ color: "blue.10" }}
                              fontWeight={"normal"}
                              color={"blue.10"}
                              pos="relative"
                            >
                              {language}
                            </Text>
                          </Box>
                          <Flex
                            transition={"all .3s ease"}
                            transform={"translateX(-10px)"}
                            opacity={0}
                            _groupHover={{
                              opacity: "100%",
                              transform: "translateX(0)",
                            }}
                            justify={"flex-end"}
                            align={"center"}
                            flex={1}
                            pos="absolute"
                            left="-30px"
                          >
                            <Icon
                              color={"blue.10"}
                              w={5}
                              h={5}
                              as={ChevronRightIcon}
                            />
                          </Flex>
                        </Stack>
                      </Link>
                    </NextLink>
                  </ul>
                );
              })}
            </Td>
          );
        })}
      </Tbody>
    </>
  );
};

const NavLink = ({ navItem, router }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("lightBlue.10", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  const [isActivePopOver, setIsActivePopOver] = useState();

  return (
    <Box key={navItem?.id}>
      <Popover
        trigger={"hover"}
        onOpen={(e) => {
          setIsActivePopOver(true);
        }}
        onClose={() => {
          setIsActivePopOver(false);
        }}
        placement={"bottom-start"}
      >
        <PopoverTrigger>
          <NextLink
            className={isActivePopOver ? "isactive" : "notactive"}
            href={navItem?.Url ?? "#"}
          >
            <Link
              rightIcon={<ChevronDownIcon />}
              p={2}
              fontWeight={500}
              color={isActivePopOver ? "orange.10" : "blue.10"}
              fontSize={{ base: "lg", md: "lg", lg: "md", xl: "lg" }}
              position="relative"
              _hover={{
                textDecoration: "none",
                color: "orange.10",
                _after: {
                  width: "full",
                  opacity: "1",
                },
              }}
              _after={{
                content: `''`,
                opacity: 0,
                width: 0,
                height: "2px",
                background: "orange.10",
                transition: "width .5s",
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
              className="navmenu-item"
            >
              {navItem?.Title}
              {navItem?.SubMenu?.length > 0 && (
                <Icon
                  color={isActivePopOver ? "orange.10" : "blue.10"}
                  w={5}
                  h={5}
                  as={ChevronDownIcon}
                />
              )}
            </Link>
          </NextLink>
        </PopoverTrigger>

        {navItem?.SubMenu?.length > 0 && (
          <PopoverContent
            borderWidth={1}
            boxShadow={"2xl"}
            bg={popoverContentBgColor}
            p={4}
            rounded={"lg"}
            minW={"lg"}
            pos={"relative"}
            className={"dropDown"}
            _after={{
              content: '""',
              pos: "absolute",
              height: "14px",
              width: "14px",
              backgroundColor: "white.10",
              left: "10%",
              bottom: "100%",
            }}
            top={"25px"}
          >
            {/* <Stack> */}
            {navItem?.Slug === "technologies" ? (
              <Table className="technologies_list">
                <TechNavList
                  data={navItem?.SubMenu}
                  router={router}
                  isActivePopOver={isActivePopOver}
                />
              </Table>
            ) : (
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                }}
                gap={"10px"}
                position={"relative"}
                _before={{
                  content:`''`,
                  width:"1px",
                  height:"100%",
                  background:"gray.100",
                  position:"absolute",
                  top: "50%",
                  left:"29%",
                  transform: "translate(-50%, -50%)",
                }}
                _after={{
                  content:`''`,
                  width:"1px",
                  height:"100%",
                  background:"gray.100",
                  position:"absolute",
                  top: "50%",
                  right:"38%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {navItem?.SubMenu.map((child, index) => (
                  <DesktopSubNav key={index} child={child} router={router} />
                ))}
              </Grid>
            )}
          </PopoverContent>
        )}
      </Popover>
    </Box>
  );
};

const DesktopSubNav = ({ child, router }) => {
  const handleSubRoutesAction = (hash) => {
    const path = hash.split("#")[0];
    const hashurl = hash.split("#")[1];

    router
      .replace(
        {
          pathname: path,
          hash: hashurl,
        },
        null,
        {
          shallow: true,
          scroll: false,
        }
      )
      .catch((e) => {
        if (!e.cancelled) {
          throw e;
        }
      });
    return;
  };

  return (
    <>
      <Link
        key={child?.slug}
        role={"group"}
        display={"block"}
        p={2}
        rounded={"md"} 
        _hover={{ bg: useColorModeValue("messenger.10", "gray.900") }}
        onClick={() => handleSubRoutesAction(child?.url)}
      >
        <Stack direction={"row"} align={"center"} pos="relative">
          <Box>
            <Text
              transition={"all .3s ease"}
              _groupHover={{ color: "blue.10" }}
              fontWeight={"normal"}
              color={"blue.10"}
              pos="relative"
            >
              {child?.Title}
            </Text>
          </Box>
          <Flex
            transition={"all .3s ease"}
            transform={"translateX(-10px)"}
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify={"flex-end"}
            align={"center"}
            flex={1}
            pos="absolute"
            left="-30px"
          >
            <Icon color={"blue.10"} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    </>
  );
};

const MobileNav = ({ links, router, onClose }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ lg: "none" }}
    >
      {links &&
        links.map((navItem, index) => (
          <MobileNavItem
            key={index}
            navItem={navItem}
            router={router}
            onClose={onClose}
          />
        ))}
    </Stack>
  );
};

const MobileNavItem = ({ navItem, router, onClose }) => {
  const { isOpen, onToggle } = useDisclosure();

  const handleMobileSubRoutesAction = (hash) => {
    router
      .replace(hash, null, {
        shallow: true,
      })
      .catch((e) => {
        if (!e.cancelled) {
          throw e;
        }
      });
    onClose();
    return;
  };

  return (
    <Stack
      spacing={4}
      onClick={navItem?.SubMenu.length > 0 ? onToggle : () => {}}
    >
      <Flex
        py={2}
        justify={"space-between"}
        align={"center"}
        fontWeight={600}
        _hover={{
          textDecoration: "none",
        }}
      >
        <NextLink href={navItem?.Url ?? "#"}>
          <Stack>
            <Text
              fontSize={{ base: "lg", md: "md", lg: "lg" }}
              fontWeight={500}
              color={useColorModeValue("blue.10", "blue.10")}
              onClick={() =>
                navItem?.SubMenu.length > 0 ? onToggle : onClose()
              }
            >
              {navItem.Title}
            </Text>
          </Stack>
        </NextLink>

        {navItem?.SubMenu.length > 0 && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {navItem?.SubMenu.length > 0 &&
            navItem?.SubMenu.map((child, index) => {
              return (
                <Link
                  key={index}
                  onClick={() => handleMobileSubRoutesAction(child?.url)}
                >
                  {child?.Title}
                </Link>
              );
            })}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, loading, error } = useQuery(HEADER_QUERY);
  const router = useRouter();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const { Header, Logo, News } = data.navbar.data.attributes;

  return (
    <>
      <Box
        px={{ base: 5, md: 8, xl: 5 }}
        py={4}
        pos="sticky"
        top="0"
        zIndex={3}
        bg="white.10"
        boxShadow="xl"
      >
        <Container as={Stack} maxW={"7xl"} p={0}>
          <Flex
            h={16}
            width="100%"
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={"row-reverse"}
            gap={"10"}
          >
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ lg: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />

            <HStack
              spacing={8}
              alignItems={"center"}
              width="100%"
              justifyContent={"space-between"}
            >
              <NextLink href="/">
                <Image
                  className="mb-0"
                  height={100}
                  width={220}
                  src={
                    process.env.NEXT_PUBLIC_SERVER_URL +
                    Logo.Icon.data.attributes.url
                  }
                  alt={Logo.Icon.data.attributes.name}
                  unoptimized
                />
              </NextLink>
              <HStack
                as={"nav"}
                spacing={4}
                display={{ base: "none", lg: "flex" }}
              >
                {Header.map((link, index) => {
                  return <NavLink key={index} navItem={link} router={router} />;
                })}
              </HStack>
            </HStack>
          </Flex>

          <Collapse in={isOpen} animateOpacity>
            <MobileNav links={Header} router={router} onClose={onClose} />
          </Collapse>
        </Container>
      </Box>
    </>
  );
}
